import { Navigator, Stage } from '../../layout/stage/Stage';
import { UnderConstruction } from '../../elements/emptystate/EmptyState';
import { useUser } from '../../contexts/auth/User';
import Card, { CardSection } from '../../elements/card/Card';
import MonthlyManufacturerInvoices from '../../sections/invoices/monthlymanufacturerinvoices/MonthlyManufacturerInvoices';
import { useTranslation } from 'react-i18next';

const Invoices = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <Stage>
      <UnderConstruction />
    </Stage>
  );
};

export default Invoices;

// {user?.isProductOwner ? (
//   <>
//     <Navigator
//       title={t('view.invoices.monthlyManufacturerInvoices.title')}
//     ></Navigator>{' '}
//     <Card bigScreenWidth={'100%'}>
//       <CardSection>
//         <MonthlyManufacturerInvoices />
//       </CardSection>
//     </Card>
//   </>
// ) : (
//   <UnderConstruction />
// )}
