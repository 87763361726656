import { useCallback } from 'react';
import { ProductMapping } from '../../../../../api/shopifyapi';
import { CommonProductImportConfig } from './useIntegrationProductsApi';

const useProductMappingHelpers = () => {
  const getMatchStatus = useCallback((mapping: ProductMapping) => {
    if (mapping.strategy === 'Mapped') {
      return 'matched';
    } else if (mapping.externalProduct && !mapping.domainProduct) {
      return 'create';
    } else if (mapping.domainProduct && !mapping.externalProduct) {
      return 'unlinked';
    } else if (mapping.strategy === 'Proposal') {
      return 'proposal';
    } else if (mapping.domainProduct && mapping.domainProduct) {
      return 'update';
    } else {
      return 'unlinked';
    }
  }, []);

  const isIdInAnyProductIdArray = (
    id: string,
    conf: CommonProductImportConfig
  ) => {
    return !!(
      conf.productIds?.includes(id) || conf.productVariantIds?.includes(id)
    );
  };

  return {
    getMatchStatus,
    isIdInAnyProductIdArray,
  };
};

export default useProductMappingHelpers;
