import './progressbar.css';
import { useTranslation } from 'react-i18next';
import Loading from '../loading/Loading';
import { CSSProperties } from 'react';
import { ReactComponent as IconCheck } from '../../../assets/icon/check.svg';

interface ProgressBarProps {
  title?: string;
  total: number;
  progress: number;
  withLoader?: boolean;
  barColor?: string;
  backgroundColor?: string;
  hideDetail?: boolean;
  height?: number;
  borderRadiusInner?: string;
  borderRadiusOuter?: string;
  colorOnCompletion?: string;
  steps?: string[];
  style?: 'default' | 'cardTop';
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  title,
  total,
  progress,
  withLoader,
  barColor,
  backgroundColor,
  hideDetail,
  height,
  borderRadiusInner,
  borderRadiusOuter,
  colorOnCompletion,
  steps,
  style,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.progressBar',
  });

  return (
    <div className={'progressBar'}>
      {withLoader ? (
        <div className={'progressBar-loader'}>
          <Loading />
        </div>
      ) : null}
      {title ? <div className={'progressBar-title'}>{title}...</div> : null}
      <div
        className={`progressBar-bar ${
          style === 'cardTop' ? 'progressBar-bar-cardTop' : ''
        }`}
        style={{
          backgroundColor: backgroundColor ?? 'rgba(244, 130, 130, 0.25)',
          height: height ?? '10px',
          borderRadius: borderRadiusOuter ?? 10,
        }}
      >
        <div
          className={'progressBar-bar-fill'}
          style={{
            width: `${(progress / total) * 100}%`,
            backgroundColor: colorOnCompletion
              ? progress === total
                ? colorOnCompletion
                : barColor ?? 'var(--color-primary)'
              : barColor ?? 'var(--color-primary)',
            borderRadius: borderRadiusInner ?? 10,
          }}
        ></div>
      </div>
      {steps
        ? steps.map((step, index) => {
            const isLastStep = index === total;
            const isDone = index < progress;
            const isLastStepDone = progress === total;
            const style: CSSProperties = {
              backgroundColor:
                isDone || (isLastStep && isLastStepDone)
                  ? barColor
                  : backgroundColor,
            };
            if (isLastStep) {
              style.right = 0;
            } else {
              style.left = `${(index / total) * 100}%`;
            }
            return (
              <div
                className={`progressBar-step ${
                  isDone || (isLastStep && isLastStepDone)
                    ? 'progressBar-step__done'
                    : ''
                }`}
                style={style}
              >
                {isLastStep ? (
                  <IconCheck className={'progressBar-step-check'} />
                ) : (
                  step
                )}
              </div>
            );
          })
        : null}
      {!hideDetail ? (
        <div className={'progressBar-detail'}>
          {`${progress} / ${total} ${t('loaded')}`}
        </div>
      ) : null}
    </div>
  );
};

export default ProgressBar;
