import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  IntegrationSubModuleResponse,
  IntegrationSubModuleType,
} from '../../../api/petcloudapi/api';
import { ShopifyIntegrationConfigResponse } from '../../../api/shopifyapi';
import useNotifications from '../../../hooks/useNotifications';
import ShopifyProductImport from '../../../sections/integration/integrationsubmodules/shopify/shopifyproductimport/ShopifyProductImport';
import ShopifyOrderExport from '../../../sections/integration/integrationsubmodules/shopify/shopifyorderexport/ShopifyOrderExport';
import ShopifyProductStocks from '../../../sections/integration/integrationsubmodules/shopify/shopifyproductstocks/ShopifyProductStocks';
import ShopifyOrderDeliveryInformationImport from '../../../sections/integration/integrationsubmodules/shopify/shopifyorderdeliveryinformationimport/ShopifyOrderDeliveryInformationImport';
import { useNavigate } from 'react-router-dom';
import IntegrationSkeleton from '../../../sections/integration/common/integrationskeleton/IntegrationSkeleton';

interface ShopifyIntegrationProps {
  integrationId: string;
  manufacturerId: string;
}

const ShopifyIntegration: React.FC<ShopifyIntegrationProps> = ({
  integrationId,
  manufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.shopify',
  });
  const api = usePetCloudApi();
  const shopifyIntegrationApi = api.shopifyIntegrationApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const link = useNavigate();

  const [integration, setIntegration] =
    useState<ShopifyIntegrationConfigResponse | null>(null);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    if (integrationId) {
      getIntegration(manufacturerId);
    }
  }, [integrationId]);

  const getIntegration = (id: string | undefined) => {
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigGet(id)
      .then((response) => {
        console.log(response);
        setIntegration(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const updateIntegration = (req: ShopifyIntegrationConfigResponse) => {
    setIntegration(req);
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigPost(req)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_successful'));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const resetConfig = () => {
    setIsResetting(true);
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigDelete(manufacturerId)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.reset_successful'));
        setIsResetting(false);
        link('/integrations');
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsResetting(false);
      });
  };

  const renderSubModules = (
    modules: IntegrationSubModuleResponse[],
    hasProductImportConfig: boolean,
    hasOrderExportConfig: boolean
  ) => {
    return modules.map((module, i) => {
      const { type, id, config } = module;
      const props = {
        manufacturerId: manufacturerId,
        integrationSubModuleId: id,
        fetchedConfig: config,
      };
      switch (type) {
        case IntegrationSubModuleType.ProductImport:
          return (
            <ShopifyProductImport
              key={i}
              {...props}
              locked={
                integration && integration.installed !== undefined
                  ? !integration.installed
                  : false
              }
            />
          );
        case IntegrationSubModuleType.ProductStockImport:
          return (
            <ShopifyProductStocks
              key={i}
              {...props}
              locked={!hasProductImportConfig}
            />
          );
        case IntegrationSubModuleType.OrderExport:
          return (
            <ShopifyOrderExport
              key={i}
              {...props}
              locked={!hasProductImportConfig}
            />
          );
        case IntegrationSubModuleType.OrderDeliveryInformationImport:
          return (
            <ShopifyOrderDeliveryInformationImport
              key={i}
              {...props}
              locked={!hasOrderExportConfig}
            />
          );
        default:
          return <div>Something went wrong...</div>;
      }
    });
  };

  return (
    <IntegrationSkeleton
      translationNamespace={'shopify'}
      integration={integration}
      integrationId={integrationId}
      renderSubModules={renderSubModules}
      updateIntegration={updateIntegration}
      resetConfig={resetConfig}
      isResetting={isResetting}
      manufacturerId={manufacturerId}
    />
  );
};

export default ShopifyIntegration;
