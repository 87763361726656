import '../integrationsetup.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import {
  InvokeShopifyAppDeploymentRequest,
  UpsertShopifyIntegrationConfigRequest,
} from '../../../../api/shopifyapi';
import FormBuilder from '../../../../elements/formbuilder/FormBuilder';
import Button from '../../../../elements/button/Button';
import ProgressBar from '../../../../elements/progressbar/ProgressBar';
import { useNavigate } from 'react-router-dom';
import highFive from '../../../../../assets/img/high_hive.svg';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import Card, { CardSection } from '../../../../elements/card/Card';
import Popup from '../../../../elements/popup/Popup';
import { IntegrationResponse } from '../../../../api/petcloudapi/api';
import useIntegrationBadges from '../../../integration/integrationcard/hooks/useIntegrationBadges';
import { ReactComponent as IconLink } from '../../../../../assets/icon/link.svg';
import { ReactComponent as IconCopy } from '../../../../../assets/icon/copy.svg';
import useNotifications from '../../../../hooks/useNotifications';

enum IntegrationSetupStep {
  Invoke,
  Pending,
  Completion,
  Finish,
}

interface ShopifySetupProps {
  integration?: IntegrationResponse;
  manufacturerId: string;
}

const ShopifySetup: React.FC<ShopifySetupProps> = ({
  integration,
  manufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations.setup.shopify',
  });
  const api = usePetCloudApi();
  const shopifyIntegrationApi = api.shopifyIntegrationApi();
  const errorHandler = useErrorHandler();
  const { getBadges } = useIntegrationBadges(integration);
  const link = useNavigate();
  const { pushNotification } = useNotifications();

  const [step, setStep] = useState(IntegrationSetupStep.Invoke);
  const [invokeRequest, setInvokeRequest] =
    useState<InvokeShopifyAppDeploymentRequest>({
      shopDomain: 'https://',
      manufacturerId: manufacturerId,
    });
  const [request, setRequest] =
    useState<UpsertShopifyIntegrationConfigRequest | null>(null);
  const [shopifyAppVersionLink, setShopifyAppVersionLink] = useState<
    string | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    // try to get a config to recover a partly completed setup
    if (integration?.config) {
      setStep(IntegrationSetupStep.Completion);
      getConfig();
    }
  }, []);

  const getConfig = () => {
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigGet(manufacturerId)
      .then((response) => {
        setRequest(response.data);
        setShopifyAppVersionLink(response.data.shopifyAppVersionLink);
        setStep(IntegrationSetupStep.Completion);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const submitConfig = (req: UpsertShopifyIntegrationConfigRequest) => {
    setIsSubmitting(true);
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigPost(req)
      .then((response) => {
        console.log(response);
        setIsSubmitting(false);
        setStep(IntegrationSetupStep.Finish);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const invokeApp = () => {
    if (isValidUrl(invokeRequest.shopDomain)) {
      setStep(IntegrationSetupStep.Pending);
      shopifyIntegrationApi
        .shopifyV1ApiIntegrationActionInvokeAppCreationPost(invokeRequest, {
          timeout: 300000,
        })
        .then((response) => {
          console.log(response);
          setRequest(response.data);
          setShopifyAppVersionLink(response.data.shopifyAppVersionLink);
          setStep(IntegrationSetupStep.Completion);
          setIsSubmitting(false);
          window.open(response.data.shopifyAppVersionLink, '_blank')?.focus();
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setStep(IntegrationSetupStep.Invoke);
        });
    }
  };

  const isValidUrl = (url: string) => {
    const regex = /^https:\/\/[a-zA-Z0-9]+/;
    if (!regex.test(url)) {
      setErrors([t('popup.step.url.error.urlInvalid')]);
      return false;
    } else {
      setErrors(null);
      return true;
    }
  };

  const renderProgressBar = (progress: number) => {
    return (
      <div className={'integrationSetup-step-progressBar'}>
        <ProgressBar
          total={3}
          progress={progress}
          barColor={'var(--color-select)'}
          backgroundColor={'var(--color-background)'}
          hideDetail
          steps={['1', '2', '3', '4']}
        />
      </div>
    );
  };

  const renderStep = () => {
    switch (step) {
      case IntegrationSetupStep.Invoke:
        return (
          <div className={'integrationSetup-step'}>
            {renderProgressBar(0)}
            <div className={'global-textElement integrationSetup-step-text'}>
              {t('popup.step.invoke.text')}
            </div>
            <img
              src={
                'https://s3.fr-par.scw.cloud/inpetto-assets/pcmf/integrationsetup/shopify/url.gif'
              }
              className={'integrationSetup-step-gif'}
              alt={'example'}
            />
            <FormBuilder
              contentObject={invokeRequest}
              setContentObject={setInvokeRequest}
              elements={[
                {
                  type: 'input',
                  title: t('popup.step.invoke.url'),
                  objectPath: 'shopDomain',
                  errors: errors ?? undefined,
                },
              ]}
            />
            <div className={'integrationSetup-actions'}>
              <Button
                cta={t('popup.step.invoke.cta')}
                width={'full'}
                look={'save'}
                action={invokeApp}
                isLoading={isSubmitting}
                active={invokeRequest.shopDomain.length > 8}
              />
            </div>
          </div>
        );
      case IntegrationSetupStep.Pending:
        return (
          <div className={'integrationSetup-step'}>
            {renderProgressBar(1)}
            <LoadingContainer message={t('popup.step.pending.text')} />
          </div>
        );
      case IntegrationSetupStep.Completion:
        if (request) {
          return (
            <div className={'integrationSetup-step'}>
              {renderProgressBar(2)}
              <div className={'global-textElement integrationSetup-step-text'}>
                {t('popup.step.completion.text')}
              </div>
              <div className={'integrationSetup-step-url'}>
                <div
                  className={
                    'integrationSetup-step-url-url global-monospaced-contrast'
                  }
                >
                  {request.url}
                </div>
                <Button
                  width={'tiny'}
                  type={'icon'}
                  look={'secondary'}
                  action={() => {
                    navigator.clipboard.writeText(request.url);
                    pushNotification(t('notifications.copiedUrl'), 'info');
                  }}
                  helperCSSClass={'integrationSetup-step-url-button'}
                >
                  <IconCopy className={'button-icon-tiny'} />
                </Button>
              </div>
              <Button
                cta={t('popup.step.completion.shopifyAppVersionLink')}
                width={'minimal'}
                look={'secondary'}
                action={
                  shopifyAppVersionLink
                    ? () =>
                        window.open(shopifyAppVersionLink, '_blank')?.focus()
                    : undefined
                }
                active={!!shopifyAppVersionLink}
                type={'icon-text'}
                helperCSSClass={'integrationSetup-step-button'}
              >
                <IconLink className={'button-icon button-icon-secondary'} />
              </Button>
              <FormBuilder
                contentObject={request}
                setContentObject={setRequest}
                elements={[
                  {
                    type: 'input',
                    title: t('popup.step.completion.installationLink'),
                    objectPath: 'installationLink',
                  },
                  {
                    type: 'input',
                    title: t('popup.step.completion.appClientSecret'),
                    objectPath: 'appClientSecret',
                  },
                ]}
              />
              <div className={'global-cardActions integrationSetup-actions'}>
                <Button
                  cta={t('popup.step.completion.cta')}
                  width={'full'}
                  look={'save'}
                  action={() => submitConfig(request)}
                />
              </div>
            </div>
          );
        } else {
          return <LoadingContainer />;
        }
      case IntegrationSetupStep.Finish:
        return (
          <div className={'integrationSetup-step'}>
            {renderProgressBar(3)}
            <div className={'global-textElement integrationSetup-step-text'}>
              {t('popup.step.finish.text')}
            </div>
            <img
              src={highFive}
              className={'integrationSetup-step-img'}
              alt={'app_install'}
            />
            <div className={'integrationSetup-actions'}>
              <Button
                cta={t('popup.step.finish.cta')}
                width={'full'}
                look={'secondary'}
                action={() => link(`/integration/shopify/}`)}
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className={'integrationSetup'}>
      <Card bigScreenWidth={'100%'}>
        {integration ? (
          <CardSection title={t('title')} badges={getBadges()}>
            <Button
              cta={t('cta_configure')}
              width={'minimal'}
              look={'secondary'}
              action={() => setPopup(true)}
            />
            <Popup toggled={popup} close={() => setPopup(false)} width={'40%'}>
              <div className={'popup-title'}>{t('popup.title')}</div>
              {renderStep()}
            </Popup>
          </CardSection>
        ) : (
          <LoadingContainer />
        )}
      </Card>
    </div>
  );
};

export default ShopifySetup;
