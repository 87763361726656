import { useParams } from 'react-router-dom';
import ShopifyIntegration from './shopify/ShopifyIntegration';
import NotFound from '../notfound/NotFound';
import InpettoClientBasedIntegration from './inpettoclientbased/InpettoClientBasedIntegration';

const Integration = () => {
  const params = useParams();
  const integrationId = params.integrationId;
  const integrationName = params.integrationName;
  const manufacturerId = params.manufacturerId;

  if (integrationId && manufacturerId) {
    switch (integrationName) {
      case 'shopify':
        return (
          <ShopifyIntegration
            integrationId={integrationId}
            manufacturerId={manufacturerId}
          />
        );
      case 'magento':
        return (
          <InpettoClientBasedIntegration
            integrationId={integrationId}
            identifier={'Magento'}
            manufacturerId={manufacturerId}
          />
        );
      case 'shopware':
        return (
          <InpettoClientBasedIntegration
            integrationId={integrationId}
            identifier={'Shopware'}
            manufacturerId={manufacturerId}
          />
        );
      default:
        return null;
    }
  } else {
    return <NotFound />;
  }
};

export default Integration;
