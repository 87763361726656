import './inpettoclientbasedsuccess.css';
import { useTranslation } from 'react-i18next';
import successImg from '../../../../../../../assets/img/app_connected.svg';

const InpettoClientBasedSuccess = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations.setup.inpettoClientBased.success',
  });

  return (
    <div className={'inpettoClientBasedSuccess'}>
      <img
        src={successImg}
        alt={'Success'}
        className={'inpettoClientBasedSuccess-img'}
      />
      <div className={'inpettoClientBasedSuccess-message'}>{t('message')}</div>
    </div>
  );
};

export default InpettoClientBasedSuccess;
