import './shopifyorderdeliveryinformationimport.css';
import { IntegrationSubModuleConfigResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import { useEffect, useState } from 'react';
import { UpsertShopifyOrderDeliveryInformationNotificationConfigRequest } from '../../../../../api/shopifyapi';
import { BadgeProps } from '../../../../../elements/badge/Badge';
import Card, { CardSection } from '../../../../../elements/card/Card';
import FormBuilder from '../../../../../elements/formbuilder/FormBuilder';
import Button from '../../../../../elements/button/Button';
import { LoadingContainer } from '../../../../../elements/loading/Loading';

const newConfig = (
  manufacturerId?: string
): UpsertShopifyOrderDeliveryInformationNotificationConfigRequest => ({
  manufacturerId,
  enabled: false,
});

interface ShopifyOrderDeliveryInformationImportProps {
  manufacturerId?: string;
  integrationSubModuleId: string;
  fetchedConfig?: IntegrationSubModuleConfigResponse | null;
  locked: boolean;
}

const ShopifyOrderDeliveryInformationImport: React.FC<
  ShopifyOrderDeliveryInformationImportProps
> = ({ manufacturerId, fetchedConfig, locked }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.common.orderDeliveryInformationImport',
  });
  const api = usePetCloudApi();
  const shopifyDeliveryInformationApi = api.shopifyDeliveryInformationApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [config, setConfig] = useState<
    | UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
    | null
    | undefined
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    if (fetchedConfig) {
      setConfig(fetchedConfig);
    } else {
      setConfig(undefined);
    }
  };

  const updateConfig = (
    req: UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
  ) => {
    setIsSubmitting(true);
    shopifyDeliveryInformationApi
      .shopifyV1ApiDeliveryInformationConfigPost(req)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_successful'));
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const getBadges = () => {
    const nodes: BadgeProps[] = [];
    if (!fetchedConfig) {
      nodes.push({
        title: t('badge.notConfigured'),
        color: 'var(--color-text_secondary)',
      });
    } else {
      nodes.push({
        title: t('badge.configured'),
        color: 'var(--color-success)',
      });
    }
    return nodes;
  };

  return (
    <div className={'shopifyOrderDeliveryInformationImport'}>
      <div className={'shopifyOrderDeliveryInformationImport-inner'}>
        <Card bigScreenWidth={'100%'}>
          <CardSection title={t('title')} badges={getBadges()} locked={locked}>
            {config !== null ? (
              config !== undefined ? (
                <div className={'shopifyOrderDeliveryInformationImport-body'}>
                  <FormBuilder
                    contentObject={config}
                    setContentObject={setConfig}
                    elements={[
                      {
                        type: 'toggle',
                        objectPath: 'enabled',
                        title: t('toggle'),
                      },
                    ]}
                  />
                  <div className={'global-cardActions-margin'}>
                    <Button
                      cta={t('cta_save')}
                      look={'save'}
                      width={'minimal'}
                      action={() => updateConfig(config)}
                      active={!locked}
                      inactiveMessage={t('inactiveMessage')}
                      isLoading={isSubmitting}
                    />
                  </div>
                </div>
              ) : (
                <Button
                  cta={t('cta_configure')}
                  look={'secondary'}
                  width={'minimal'}
                  action={() => setConfig(newConfig(manufacturerId))}
                  active={!locked}
                  inactiveMessage={t('inactiveMessage')}
                />
              )
            ) : (
              <LoadingContainer />
            )}
          </CardSection>
        </Card>
      </div>
    </div>
  );
};

export default ShopifyOrderDeliveryInformationImport;
