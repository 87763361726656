import './inpettoclientbasedcredentials.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { InpettoClientHmacKeyPair } from '../../../../../../api/shopwareapi';
import { IntegrationResponse } from '../../../../../../api/petcloudapi/api';
import StepNavigation from '../../stepnavigation/StepNavigation';
import useInpettoClientBasedIntegrationApi from '../../hooks/useInpettoClientBasedIntegrationApi';
import Input from '../../../../../../elements/input/Input';
import { LoadingContainer } from '../../../../../../elements/loading/Loading';
import { EmptyState } from '../../../../../../elements/emptystate/EmptyState';
import InformationBox from '../../../../../../elements/informationbox/InformationBox';
import Button from '../../../../../../elements/button/Button';

interface InpettoClientBasedCredentialsProps {
  onNext: () => void;
  onBack: () => void;
  integration: IntegrationResponse;
  manufacturerId?: string | null;
}

const InpettoClientBasedCredentials: React.FC<
  InpettoClientBasedCredentialsProps
> = ({ onNext, onBack, integration, manufacturerId }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations.setup.inpettoClientBased.credentials',
  });
  const { integrationGenerateHmacCredentialsPost } =
    useInpettoClientBasedIntegrationApi(integration.identifier);
  const [response, setResponse] = useState<InpettoClientHmacKeyPair | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!response && !integration.config?.credentialId) {
      createCredentials();
    }
  }, []);

  const createCredentials = () => {
    if (manufacturerId) {
      setIsSubmitting(true);
      integrationGenerateHmacCredentialsPost({
        manufacturerId: manufacturerId,
        integrationId: integration.id,
      })
        .then((response) => {
          setResponse(response);
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  };

  // hmacSenderKey and hmacReceiver keys are switched below for UX reasons

  if (manufacturerId) {
    if (response) {
      return (
        <div className={'inpettoClientBasedCredentials'}>
          <div className={'popup-title'}>{t('title')}</div>
          <div className={'global-textElement'} style={{ maxWidth: 400 }}>
            {t('text')}
          </div>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('hmacReceiverKey')}
                content={response?.hmacSenderKey}
                withClipboard
              />
            </div>
          </div>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('hmacSenderKey')}
                content={response?.hmacReceiverKey}
                withClipboard
              />
            </div>
          </div>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('manufacturerId')}
                content={manufacturerId}
                withClipboard
              />
            </div>
          </div>
          <InformationBox
            type={'warning'}
            title={t('warning.title')}
            paragraphs={[t('warning.p1'), t('warning.p2')]}
            maxWidth={400}
          />
          <StepNavigation onNext={onNext} onBack={onBack} />
        </div>
      );
    } else if (integration.config?.credentialId) {
      return (
        <div className={'inpettoClientBasedCredentials-regenerate'}>
          <div className={'popup-title'}>{t('title')}</div>
          <div className={'global-textElement'} style={{ maxWidth: 400 }}>
            {t('text')}
          </div>
          <InformationBox
            type={'info'}
            title={t('info.title')}
            paragraphs={[t('info.text')]}
            maxWidth={400}
          />
          <Button
            cta={t('regenerate')}
            look={'secondary-danger'}
            width={'full'}
            action={createCredentials}
            isLoading={isSubmitting}
          />
          <StepNavigation onNext={onNext} onBack={onBack} />
        </div>
      );
    } else {
      return <LoadingContainer message={t('loading')} />;
    }
  } else {
    return <EmptyState message={t('noManufacturerId')} />;
  }
};

export default InpettoClientBasedCredentials;
