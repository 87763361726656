import './stepnavigation.css';
import Button from '../../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';

interface StepNavigationProps {
  onNext?: () => void;
  isNextDisabled?: boolean;
  onBack?: () => void;
  isLoadingNext?: boolean;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  onNext,
  isNextDisabled,
  onBack,
  isLoadingNext,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations.setup.inpettoClientBased.stepNavigation',
  });

  return (
    <div
      className={`stepNavigation global-cardActions ${
        onNext && onBack ? 'global-cardActions-spaceBetween' : ''
      }`}
    >
      {onBack ? (
        <Button
          cta={t('back')}
          look={'secondary'}
          width={'minimal'}
          action={onBack}
        />
      ) : null}
      {onNext ? (
        <Button
          cta={t('next')}
          look={'secondary'}
          width={'minimal'}
          action={onNext}
          isLoading={isLoadingNext}
          active={!isNextDisabled}
        />
      ) : null}
    </div>
  );
};

export default StepNavigation;
