import {
  ShopifyProductImportConfigResponse,
  UpsertShopifyProductMappingsRequest,
} from '../../../../../api/shopifyapi';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useProductMappingHelpers from './useProductMappingHelpers';
import { ExtendedProductMapping } from '../ProductMappings';
import useInpettoClientBasedIntegrationApi from '../../../setup/inpettoclientbased/hooks/useInpettoClientBasedIntegrationApi';
import {
  InpettoClientBasedProductMappingRequest,
  UpsertInpettoClientBasedProductMappingsRequest,
} from '../../../../../api/magentoapi';

export type CommonProductImportConfig = {
  enabled?: boolean;
  integrationSubModuleId?: string;
  productIds?: string[] | null;
  productVariantIds?: string[] | null;
  manufacturerId?: string | null;
};

const useIntegrationProductsApi = (
  identifier: string,
  manufacturerId: string
) => {
  const api = usePetCloudApi();
  const shopifyProductImportApi = api.shopifyProductImportApi();
  const magentoProductImportApi = api.magentoProductImportApi();
  const shopwareProductImportApi = api.shopwareProductImportApi();
  const { productsConfigGet, productsConfigPost } =
    useInpettoClientBasedIntegrationApi(identifier);
  const { getMatchStatus, isIdInAnyProductIdArray } =
    useProductMappingHelpers();
  const errorHandler = useErrorHandler();

  // SHOPIFY

  const shopifyV1ApiProductsConfigGet =
    (): Promise<CommonProductImportConfig> => {
      return new Promise<CommonProductImportConfig>((resolve, reject) => {
        shopifyProductImportApi
          .shopifyV1ApiProductsConfigGet(manufacturerId)
          .then((response) => {
            console.log(response);
            // parse productIds and productVariantIds to strings
            resolve({
              ...response.data,
              productIds: response.data.productIds?.map((x) => x.toString()),
              productVariantIds: response.data.productVariantIds?.map((x) =>
                x.toString()
              ),
            });
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    };

  const shopifyV1ApiProductsConfigPost = (req: CommonProductImportConfig) => {
    // parse productIds and productVariantIds back to numbers
    const request = {
      ...req,
      productIds: req.productIds?.map((x) => parseInt(x)),
      productVariantIds: req.productVariantIds?.map((x) => parseInt(x)),
    };

    return new Promise<any>((resolve, reject) => {
      shopifyProductImportApi
        .shopifyV1ApiProductsConfigPost(request)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const shopifyV1ApiProductsMappingsGet = (
    conf: CommonProductImportConfig
  ): Promise<ExtendedProductMapping[]> => {
    return new Promise<ExtendedProductMapping[]>((resolve, reject) => {
      shopifyProductImportApi
        .shopifyV1ApiProductsMappingsGet(manufacturerId)
        .then((response) => {
          console.log(response);
          const result = response.data.map((mapping) => {
            const shopifyProductId = mapping.externalProduct?.id;
            const result: ExtendedProductMapping = {
              ...mapping,
              id: crypto.randomUUID(),
              selected: false,
              status: getMatchStatus(mapping),
            };
            if (shopifyProductId) {
              result.selected = isIdInAnyProductIdArray(shopifyProductId, conf);
            } else {
              result.disabled = true;
            }
            return result;
          });
          resolve(result);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const shopifyV1ApiProductsMappingsPost = (
    mappings: ExtendedProductMapping[]
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const request: UpsertShopifyProductMappingsRequest = {
        manufacturerId: manufacturerId,
        mappings: mappings
          .filter((x) => x.status !== 'proposal')
          .map((mapping) => ({
            shopifyParentProductId:
              mapping.externalProduct?.parentId ?? mapping.externalProduct?.id,
            shopifyVariantProductId: mapping.externalProduct?.parentId
              ? mapping.externalProduct?.id
              : null,
            domainProductId: mapping.domainProduct?.id,
          })),
      };
      shopifyProductImportApi
        .shopifyV1ApiProductsMappingsPost(request)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  // MAGENTO

  const magentoV1ApiProductsMappingsGet = (
    conf: CommonProductImportConfig
  ): Promise<ExtendedProductMapping[]> => {
    return new Promise<ExtendedProductMapping[]>((resolve, reject) => {
      magentoProductImportApi
        .magentoV1ApiProductsMappingsGet(manufacturerId)
        .then((response) => {
          console.log(response);
          const result = response.data.map((mapping) => {
            const shopifyProductId = mapping.externalProduct?.id;
            const result: ExtendedProductMapping = {
              ...mapping,
              id: crypto.randomUUID(),
              selected: false,
              status: getMatchStatus(mapping),
            };
            if (shopifyProductId) {
              result.selected = isIdInAnyProductIdArray(shopifyProductId, conf);
            } else {
              result.disabled = true;
            }
            return result;
          });
          resolve(result);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const shopwareV1ApiProductsMappingsGet = (
    conf: CommonProductImportConfig
  ): Promise<ExtendedProductMapping[]> => {
    return new Promise<ExtendedProductMapping[]>((resolve, reject) => {
      shopwareProductImportApi
        .shopwareV1ApiProductsMappingsGet(manufacturerId)
        .then((response) => {
          console.log(response);
          const result = response.data.map((mapping) => {
            const shopifyProductId = mapping.externalProduct?.id;
            const result: ExtendedProductMapping = {
              ...mapping,
              id: crypto.randomUUID(),
              selected: false,
              status: getMatchStatus(mapping),
            };
            if (shopifyProductId) {
              result.selected = isIdInAnyProductIdArray(shopifyProductId, conf);
            } else {
              result.disabled = true;
            }
            return result;
          });
          resolve(result);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const magentoV1ApiProductsMappingsPost = (
    mappings: ExtendedProductMapping[]
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const request: UpsertInpettoClientBasedProductMappingsRequest = {
        manufacturerId: manufacturerId,
        mappings: mappings
          .filter((x) => x.status !== 'proposal')
          .map(
            (mapping): InpettoClientBasedProductMappingRequest => ({
              externalParentProductId:
                mapping.externalProduct?.parentId ??
                mapping.externalProduct?.id,
              externalVariantProductId: mapping.externalProduct?.parentId
                ? mapping.externalProduct?.id
                : null,
              domainProductId: mapping.domainProduct?.id,
            })
          ),
      };
      magentoProductImportApi
        .magentoV1ApiProductsMappingsPost(request)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const shopwareV1ApiProductsMappingsPost = (
    mappings: ExtendedProductMapping[]
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const request: UpsertInpettoClientBasedProductMappingsRequest = {
        manufacturerId: manufacturerId,
        mappings: mappings
          .filter((x) => x.status !== 'proposal')
          .map(
            (mapping): InpettoClientBasedProductMappingRequest => ({
              externalParentProductId:
                mapping.externalProduct?.parentId ??
                mapping.externalProduct?.id,
              externalVariantProductId: mapping.externalProduct?.parentId
                ? mapping.externalProduct?.id
                : null,
              domainProductId: mapping.domainProduct?.id,
            })
          ),
      };
      shopwareProductImportApi
        .shopwareV1ApiProductsMappingsPost(request)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  if (identifier === 'Shopify') {
    // Shopify
    return {
      productsConfigGet: shopifyV1ApiProductsConfigGet,
      productsConfigPost: shopifyV1ApiProductsConfigPost,
      productsMappingsGet: shopifyV1ApiProductsMappingsGet,
      productsMappingsPost: shopifyV1ApiProductsMappingsPost,
    };
  } else if (identifier === 'Magento') {
    // Magento
    return {
      productsConfigGet: productsConfigGet,
      productsConfigPost: productsConfigPost,
      productsMappingsGet: magentoV1ApiProductsMappingsGet,
      productsMappingsPost: magentoV1ApiProductsMappingsPost,
    };
  } else {
    // Shopware
    return {
      productsConfigGet: productsConfigGet,
      productsConfigPost: productsConfigPost,
      productsMappingsGet: shopwareV1ApiProductsMappingsGet,
      productsMappingsPost: shopwareV1ApiProductsMappingsPost,
    };
  }
};

export default useIntegrationProductsApi;
