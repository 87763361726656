import './plugindownload.css';
import Button from '../../../../../../../../elements/button/Button';
import useFileDownload from '../../../../../../../../hooks/useFileDownload';
import { ReactComponent as IconDownload } from '../../../../../../../../../assets/icon/import.svg';
import { DownloadablePlugin } from '../InpettoClientBasedPlugin';
import Badge from '../../../../../../../../elements/badge/Badge';

interface PluginDownloadProps {
  plugin: DownloadablePlugin;
}

const PluginDownload: React.FC<PluginDownloadProps> = ({ plugin }) => {
  const { downloadFileFormUrl } = useFileDownload();
  const { name, url, fileSize, fileName, requiredVersions } = plugin;

  return (
    <div className={'pluginDownload'}>
      <div className={'pluginDownload-meta'}>
        <div className={'pluginDownload-meta-title'}>
          <div className={'pluginDownload-meta-title-name'}>{name}</div>
          <div className={'pluginDownload-meta-title-versions'}>
            {requiredVersions.map((version, i) => (
              <Badge
                title={`${version.name} ${version.release}`}
                color={'var(--color-text_secondary)'}
              />
            ))}
          </div>
        </div>
        <div className={'pluginDownload-meta-sub'}>
          <div className={'pluginDownload-meta-sub-fileName'}>{fileName}</div>
          <div className={'pluginDownload-meta-sub-fileSize'}>{fileSize}</div>
        </div>
      </div>
      <Button
        type={'icon'}
        width={'minimal'}
        look={'secondary'}
        action={() => downloadFileFormUrl(name, url)}
        helperCSSClass={'pluginDownload-btn'}
      >
        <IconDownload className={'button-icon button-icon-secondary'} />
      </Button>
    </div>
  );
};

export default PluginDownload;
