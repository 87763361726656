import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useMemo, useState } from 'react';
import { IntegrationSubModuleResponse } from '../../../../api/petcloudapi/api';

const useIntegrationSubModules = (
  integrationId: string,
  manufacturerId?: string
) => {
  const api = usePetCloudApi();
  const integrationsApi = api.integrationsApi();
  const errorHandler = useErrorHandler();

  const [submodules, setSubModules] = useState<
    IntegrationSubModuleResponse[] | null
  >(null);

  useEffect(() => {
    if (integrationId) {
      getSubModules(integrationId, manufacturerId);
    }
  }, [integrationId]);

  const getSubModules = (integrationId: string, manufacturerId?: string) => {
    integrationsApi
      .integrationsGetIntegrationSubModules(integrationId, manufacturerId)
      .then((response) => {
        console.log(response);
        setSubModules(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const hasProductImportConfig = useMemo(() => {
    if (submodules) {
      return !!submodules.find((x) => x.type === 'ProductImport')?.config;
    } else {
      return null;
    }
  }, [submodules]);

  const hasOrderExportConfig = useMemo(() => {
    if (submodules) {
      return !!submodules.find((x) => x.type === 'OrderExport')?.config;
    } else {
      return null;
    }
  }, [submodules]);

  const sortedSubModules = useMemo(() => {
    if (submodules === null) return null;
    // order the modules so their appearance makes sense from an UX perspective
    const sortedModules: IntegrationSubModuleResponse[] = [];
    const productImport = submodules.find((x) => x.type === 'ProductImport');
    if (productImport) {
      sortedModules.push(productImport);
    }
    const productStockImport = submodules.find(
      (x) => x.type === 'ProductStockImport'
    );
    if (productStockImport) {
      sortedModules.push(productStockImport);
    }
    const orderExport = submodules.find((x) => x.type === 'OrderExport');
    if (orderExport) {
      sortedModules.push(orderExport);
    }
    const orderDeliveryInformationImport = submodules.find(
      (x) => x.type === 'OrderDeliveryInformationImport'
    );
    if (orderDeliveryInformationImport) {
      sortedModules.push(orderDeliveryInformationImport);
    }

    return sortedModules;
  }, [submodules]);

  return {
    submodules,
    sortedSubModules,
    hasProductImportConfig,
    hasOrderExportConfig,
  };
};

export default useIntegrationSubModules;
