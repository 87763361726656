import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import {
  InpettoClientBasedIntegrationStatusResponse,
  InpettoClientHmacKeyPair,
  UpsertHmacCredentialsRequest,
  UpsertInpettoClientBasedIntegrationConfigRequest,
} from '../../../../../api/shopwareapi';
import {
  InpettoClientBasedIntegrationConfigResponse,
  InpettoClientBasedProductStockNotificationConfigResponse,
  InpettoClientBasedProductSyncRequest,
  UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest,
  UpsertInpettoClientBasedOrderExportConfigRequest,
  UpsertInpettoClientBasedProductStockNotificationConfigRequest,
} from '../../../../../api/magentoapi';
import { CommonProductImportConfig } from '../../../common/productmappings/hooks/useIntegrationProductsApi';

const useInpettoClientBasedIntegrationApi = (identifier: string) => {
  const api = usePetCloudApi();
  const shopwareIntegrationApi = api.shopwareIntegrationApi();
  const magentoIntegrationApi = api.magentoIntegrationApi();
  const magentoProductImportApi = api.magentoProductImportApi();
  const shopwareProductImportApi = api.shopwareProductImportApi();
  const magentoProductStocksApi = api.magentoProductStocksApi();
  const shopwareProductStocksApi = api.shopwareProductStocksApi();
  const magentoOrderExportApi = api.magentoOrderExportApi();
  const shopwareOrderExportApi = api.shopwareOrderExportApi();
  const magentoDeliveryInformationApi = api.magentoDeliveryInformationApi();
  const shopwareDeliveryInformationApi = api.shopwareDeliveryInformationApi();
  const errorHandler = useErrorHandler();

  const magentoV1ApiDeliveryInformationConfigPost = (
    req: UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      magentoDeliveryInformationApi
        .magentoV1ApiDeliveryInformationConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const shopwareV1ApiDeliveryInformationConfigPost = (
    req: UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      shopwareDeliveryInformationApi
        .shopwareV1ApiDeliveryInformationConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const magentoV1ApiOrdersConfigPost = (
    req: UpsertInpettoClientBasedOrderExportConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      magentoOrderExportApi
        .magentoV1ApiOrdersConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const shopwareV1ApiOrdersConfigPost = (
    req: UpsertInpettoClientBasedOrderExportConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      shopwareOrderExportApi
        .shopwareV1ApiOrdersConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const magentoV1ApiProductStocksConfigPost = (
    req: UpsertInpettoClientBasedProductStockNotificationConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      magentoProductStocksApi
        .magentoV1ApiProductStocksConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const shopwareV1ApiProductStocksConfigPost = (
    req: UpsertInpettoClientBasedProductStockNotificationConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      shopwareProductStocksApi
        .shopwareV1ApiProductStocksConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const magentoV1ApiProductStocksConfigGet = (
    manufacturerId: string
  ): Promise<InpettoClientBasedProductStockNotificationConfigResponse> => {
    return new Promise((resolve, reject) => {
      magentoProductStocksApi
        .magentoV1ApiProductStocksConfigGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const shopwareV1ApiProductStocksConfigGet = (
    manufacturerId: string
  ): Promise<InpettoClientBasedProductStockNotificationConfigResponse> => {
    return new Promise((resolve, reject) => {
      shopwareProductStocksApi
        .shopwareV1ApiProductStocksConfigGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const magentoV1ApiProductsActionSyncPost = (
    req: InpettoClientBasedProductSyncRequest
  ) => {
    return new Promise((resolve, reject) => {
      magentoProductImportApi
        .magentoV1ApiProductsActionSyncPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const shopwareV1ApiProductsActionSyncPost = (
    req: InpettoClientBasedProductSyncRequest
  ) => {
    return new Promise((resolve, reject) => {
      shopwareProductImportApi
        .shopwareV1ApiProductsActionSyncPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const magentoV1ApiProductsConfigGet = (
    manufacturerId: string
  ): Promise<CommonProductImportConfig> => {
    return new Promise((resolve, reject) => {
      magentoProductImportApi
        .magentoV1ApiProductsConfigGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const magentoV1ApiProductsConfigPost = (req: CommonProductImportConfig) => {
    return new Promise((resolve, reject) => {
      magentoProductImportApi
        .magentoV1ApiProductsConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const shopwareV1ApiProductsConfigPost = (req: CommonProductImportConfig) => {
    return new Promise((resolve, reject) => {
      shopwareProductImportApi
        .shopwareV1ApiProductsConfigPost(req)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const shopwareV1ApiProductsConfigGet = (
    manufacturerId: string
  ): Promise<CommonProductImportConfig> => {
    return new Promise((resolve, reject) => {
      shopwareProductImportApi
        .shopwareV1ApiProductsConfigGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const shopwareV1ApiIntegrationConfigDelete = (manufacturerId: string) => {
    return new Promise((resolve, reject) => {
      shopwareIntegrationApi
        .shopwareV1ApiIntegrationConfigDelete(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const magentoV1ApiIntegrationConfigDelete = (manufacturerId: string) => {
    return new Promise((resolve, reject) => {
      magentoIntegrationApi
        .magentoV1ApiIntegrationConfigDelete(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const shopwareV1ApiIntegrationConfigGet = (
    manufacturerId: string
  ): Promise<InpettoClientBasedIntegrationConfigResponse> => {
    return new Promise((resolve, reject) => {
      shopwareIntegrationApi
        .shopwareV1ApiIntegrationConfigGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const magentoV1ApiIntegrationConfigGet = (
    manufacturerId: string
  ): Promise<InpettoClientBasedIntegrationConfigResponse> => {
    return new Promise((resolve, reject) => {
      magentoIntegrationApi
        .magentoV1ApiIntegrationConfigGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const shopwareV1ApiIntegrationConfigPost = (
    request: UpsertInpettoClientBasedIntegrationConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      shopwareIntegrationApi
        .shopwareV1ApiIntegrationConfigPost(request)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const magentoV1ApiIntegrationConfigPost = (
    request: UpsertInpettoClientBasedIntegrationConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      magentoIntegrationApi
        .magentoV1ApiIntegrationConfigPost(request)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const shopwareV1ApiIntegrationGenerateHmacCredentialsPost = (
    request: UpsertHmacCredentialsRequest
  ): Promise<InpettoClientHmacKeyPair> => {
    return new Promise((resolve, reject) => {
      shopwareIntegrationApi
        .shopwareV1ApiIntegrationGenerateHmacCredentialsPost(request)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const magentoV1ApiIntegrationGenerateHmacCredentialsPost = (
    request: UpsertHmacCredentialsRequest
  ): Promise<InpettoClientHmacKeyPair> => {
    return new Promise((resolve, reject) => {
      magentoIntegrationApi
        .magentoV1ApiIntegrationGenerateHmacCredentialsPost(request)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const magentoV1ApiIntegrationStatusGet = (
    manufacturerId: string
  ): Promise<InpettoClientBasedIntegrationStatusResponse> => {
    return new Promise((resolve, reject) => {
      magentoIntegrationApi
        .magentoV1ApiIntegrationStatusGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const shopwareV1ApiIntegrationStatusGet = (
    manufacturerId: string
  ): Promise<InpettoClientBasedIntegrationStatusResponse> => {
    return new Promise((resolve, reject) => {
      shopwareIntegrationApi
        .shopwareV1ApiIntegrationStatusGet(manufacturerId)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  if (identifier === 'Shopware') {
    return {
      deliveryInformationConfigPost: shopwareV1ApiDeliveryInformationConfigPost,
      ordersConfigPost: shopwareV1ApiOrdersConfigPost,
      productStocksConfigPost: shopwareV1ApiProductStocksConfigPost,
      productStocksConfigGet: shopwareV1ApiProductStocksConfigGet,
      productsActionSyncPost: shopwareV1ApiProductsActionSyncPost,
      productsConfigGet: shopwareV1ApiProductsConfigGet,
      productsConfigPost: shopwareV1ApiProductsConfigPost,
      integrationConfigDelete: shopwareV1ApiIntegrationConfigDelete,
      integrationConfigGet: shopwareV1ApiIntegrationConfigGet,
      integrationConfigPost: shopwareV1ApiIntegrationConfigPost,
      integrationGenerateHmacCredentialsPost:
        shopwareV1ApiIntegrationGenerateHmacCredentialsPost,
      integrationStatusGet: shopwareV1ApiIntegrationStatusGet,
    };
  } else {
    return {
      deliveryInformationConfigPost: magentoV1ApiDeliveryInformationConfigPost,
      ordersConfigPost: magentoV1ApiOrdersConfigPost,
      productStocksConfigPost: magentoV1ApiProductStocksConfigPost,
      productStocksConfigGet: magentoV1ApiProductStocksConfigGet,
      productsActionSyncPost: magentoV1ApiProductsActionSyncPost,
      productsConfigGet: magentoV1ApiProductsConfigGet,
      productsConfigPost: magentoV1ApiProductsConfigPost,
      integrationConfigDelete: magentoV1ApiIntegrationConfigDelete,
      integrationConfigGet: magentoV1ApiIntegrationConfigGet,
      integrationConfigPost: magentoV1ApiIntegrationConfigPost,
      integrationGenerateHmacCredentialsPost:
        magentoV1ApiIntegrationGenerateHmacCredentialsPost,
      integrationStatusGet: magentoV1ApiIntegrationStatusGet,
    };
  }
};

export default useInpettoClientBasedIntegrationApi;
