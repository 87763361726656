import './inpettoclientbasedsetupurl.css';
import { UpsertInpettoClientBasedIntegrationConfigRequest } from '../../../../../../api/shopwareapi';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../../../../../elements/formbuilder/FormBuilder';
import { useEffect, useState } from 'react';
import StepNavigation from '../../stepnavigation/StepNavigation';
import { IntegrationResponse } from '../../../../../../api/petcloudapi/api';
import useInpettoClientBasedIntegrationApi from '../../hooks/useInpettoClientBasedIntegrationApi';

interface InpettoClientBasedSetupUrlProps {
  onNext: () => void;
  integration: IntegrationResponse;
  manufacturerId?: string | null;
}

const InpettoClientBasedSetupUrl: React.FC<InpettoClientBasedSetupUrlProps> = ({
  onNext,
  integration,
  manufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations.setup.inpettoClientBased.url',
  });
  const { integrationConfigPost } = useInpettoClientBasedIntegrationApi(
    integration.identifier
  );
  const [errors, setErrors] = useState<string[] | null>(null);

  const getUrlFromConfig = () => {
    if (integration.config?.config) {
      return JSON.parse(integration.config.config).Url;
    } else {
      return null;
    }
  };

  const [request, setRequest] =
    useState<UpsertInpettoClientBasedIntegrationConfigRequest>({
      url: getUrlFromConfig() ?? '',
      integrationId: integration.id,
      manufacturerId: manufacturerId,
      enabled: false,
      installed: false,
    });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = () => {
    if (request.url !== getUrlFromConfig()) {
      setIsSubmitting(true);
      integrationConfigPost(request)
        .then(() => {
          setIsSubmitting(false);
          onNext();
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    } else {
      onNext();
    }
  };

  useEffect(() => {
    const { url } = request;
    if (url !== '') {
      if (url.startsWith('https://') || url.startsWith('http://')) {
        setErrors(null);
      } else {
        setErrors([t('errors.invalidPrefix')]);
      }
    }
  }, [request]);

  return (
    <div className={'inpettoClientBasedSetupUrl'}>
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement'}>
        {t(`${integration.identifier}.text`)}
      </div>
      <FormBuilder
        contentObject={request}
        setContentObject={setRequest}
        elements={[{ type: 'input', objectPath: 'url', errors: errors }]}
      />
      <StepNavigation
        onNext={submit}
        isLoadingNext={isSubmitting}
        isNextDisabled={errors !== null}
      />
    </div>
  );
};

export default InpettoClientBasedSetupUrl;
