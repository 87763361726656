import './connectiontestresults.css';
import { InpettoClientBasedIntegrationStatusResponse } from '../../../../../../../api/shopwareapi';
import { useTranslation } from 'react-i18next';
import Badge from '../../../../../../../elements/badge/Badge';

interface ConnectionTestResultsProps {
  response: InpettoClientBasedIntegrationStatusResponse;
  statusArray: [string, boolean][];
}

const ConnectionTestResults: React.FC<ConnectionTestResultsProps> = ({
  response,
  statusArray,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'view.integrations.setup.inpettoClientBased.connectionTest.results',
  });

  const getStatus = (key: string, bool: boolean) => {
    if (!bool) {
      return 'error';
    } else {
      if (response.errors?.find((error) => error.key === key)) {
        return 'warning';
      } else {
        return 'ok';
      }
    }
  };

  const renderErrors = (key: string, animationDelay: string) => {
    const errors = response.errors?.filter((error) => error.key === key);
    if (errors && errors.length > 0) {
      return errors.map((error, i) => {
        return (
          <div
            className={'connectionTestResult-error'}
            style={{ animationDelay: animationDelay }}
            key={i}
          >
            <div className={'connectionTestResult-error-message'}>
              {error.value}
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <div className={'connectionTestResults'}>
      {statusArray.map(([key, bool], index) => {
        const status = getStatus(key, bool);
        const resultDelay = `${index * 0.5 + 0.5}s`;
        return (
          <div key={key} className={'connectionTestResult'}>
            <div className={'connectionTestResult-name'}>{t(key)}</div>
            <div className={'connectionTestResult-status'}>
              <div className={`connectionTestResult-status-bar`}>
                <div
                  className={`connectionTestResult-status-bar-fill connectionTestResult-status-bar-fill__${status}`}
                  style={{ animationDelay: `${index * 0.5}s` }}
                />
              </div>
              <div
                className={`connectionTestResult-status-badge connectionTestResult-status-badge__${status}`}
                style={{ animationDelay: resultDelay }}
              >
                {t(`status.${status}`)}
              </div>
            </div>
            {renderErrors(key, resultDelay)}
          </div>
        );
      })}
      <div className={'connectionTestResults-versions'}>
        {response.versions?.map((version, i) => (
          <Badge
            title={`${version.key} ${version.value}`}
            color={'var(--color-text_primary)'}
          />
        ))}
      </div>
    </div>
  );
};

export default ConnectionTestResults;
