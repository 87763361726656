/* tslint:disable */
/* eslint-disable */
/**
 * inpetto Connect API for Magento | v1
 * Magento
 *
 * The version of the OpenAPI document: 1
 * Contact: support@inpetto.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DeliveryPosition
 */
export interface DeliveryPosition {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPosition
     */
    'productId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPosition
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface Destination
 */
export interface Destination {
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'country_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Destination
     */
    'province_code'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum EntityUpsertOperationEnum {
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete'
}

/**
 * 
 * @export
 * @interface EntityUpsertResult
 */
export interface EntityUpsertResult {
    /**
     * 
     * @type {boolean}
     * @memberof EntityUpsertResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityUpsertResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EntityUpsertResult
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {EntityUpsertOperationEnum}
     * @memberof EntityUpsertResult
     */
    'operation'?: EntityUpsertOperationEnum;
}
/**
 * 
 * @export
 * @interface FulfillmentsRequest
 */
export interface FulfillmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof FulfillmentsRequest
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FulfillmentsRequest
     */
    'order_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'service'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'tracking_company'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FulfillmentsRequest
     */
    'shipment_status'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof FulfillmentsRequest
     */
    'location_id'?: number | null;
    /**
     * 
     * @type {any}
     * @memberof FulfillmentsRequest
     */
    'origin_address'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {Destination}
     * @memberof FulfillmentsRequest
     */
    'destination'?: Destination;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof FulfillmentsRequest
     */
    'line_items'?: Array<LineItem> | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'tracking_number'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FulfillmentsRequest
     */
    'tracking_numbers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'tracking_url'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FulfillmentsRequest
     */
    'tracking_urls'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof FulfillmentsRequest
     */
    'receipt'?: object;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentsRequest
     */
    'admin_graphql_api_id'?: string | null;
}
/**
 * 
 * @export
 * @interface GetInpettoClientBasedProductsRequest
 */
export interface GetInpettoClientBasedProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetInpettoClientBasedProductsRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface GetShopifyOrderDeliveryInformationNotificationConfigResponse
 */
export interface GetShopifyOrderDeliveryInformationNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'registered'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetShopifyOrderDeliveryInformationNotificationConfigResponse
     */
    'shippingProviderMapping'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface GetShopifyProductStockNotificationConfigResponse
 */
export interface GetShopifyProductStockNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetShopifyProductStockNotificationConfigResponse
     */
    'registered'?: boolean;
}
/**
 * 
 * @export
 * @interface GetShopifyProductsRequest
 */
export interface GetShopifyProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetShopifyProductsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {ShopifyProductVariantFilterCriteria}
     * @memberof GetShopifyProductsRequest
     */
    'filterCriteria'?: ShopifyProductVariantFilterCriteria;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedIntegrationConfigResponse
 */
export interface InpettoClientBasedIntegrationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'integrationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationConfigResponse
     */
    'installed'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum InpettoClientBasedIntegrationStatusPropertyName {
    IsReachable = 'isReachable',
    HasConfiguredKeys = 'hasConfiguredKeys',
    HasConfiguredManufacturerId = 'hasConfiguredManufacturerId',
    CanSignRequests = 'canSignRequests',
    CanAuthenticateResponses = 'canAuthenticateResponses'
}

/**
 * 
 * @export
 * @interface InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair
 */
export interface InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair {
    /**
     * 
     * @type {InpettoClientBasedIntegrationStatusPropertyName}
     * @memberof InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair
     */
    'key'?: InpettoClientBasedIntegrationStatusPropertyName;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedIntegrationStatusResponse
 */
export interface InpettoClientBasedIntegrationStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'isReachable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'hasConfiguredKeys'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'hasConfiguredManufacturerId'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'canSignRequests'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'canAuthenticateResponses'?: boolean;
    /**
     * 
     * @type {Array<VersionKeyEnumStringKeyValuePair>}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'versions'?: Array<VersionKeyEnumStringKeyValuePair> | null;
    /**
     * 
     * @type {Array<InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair>}
     * @memberof InpettoClientBasedIntegrationStatusResponse
     */
    'errors'?: Array<InpettoClientBasedIntegrationStatusPropertyNameStringKeyValuePair> | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
 */
export interface InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse
     */
    'registered'?: boolean;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedOrderExportConfigResponse
 */
export interface InpettoClientBasedOrderExportConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'integrationSubModuleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedOrderExportConfigResponse
     */
    'cronExpression'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductImportConfigResponse
 */
export interface InpettoClientBasedProductImportConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'productVariantIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductImportConfigResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductMappingRequest
 */
export interface InpettoClientBasedProductMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'externalParentProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'externalVariantProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'externalVariantProductStockId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'domainProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductMappingRequest
     */
    'domainProductStockId'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductStockNotificationConfigResponse
 */
export interface InpettoClientBasedProductStockNotificationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InpettoClientBasedProductStockNotificationConfigResponse
     */
    'registered'?: boolean;
}
/**
 * 
 * @export
 * @interface InpettoClientBasedProductSyncRequest
 */
export interface InpettoClientBasedProductSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientBasedProductSyncRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface InpettoClientHmacKeyPair
 */
export interface InpettoClientHmacKeyPair {
    /**
     * 
     * @type {string}
     * @memberof InpettoClientHmacKeyPair
     */
    'hmacSenderKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InpettoClientHmacKeyPair
     */
    'hmacReceiverKey'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IntegrationIdentifierType {
    Shopware = 'Shopware',
    Shopify = 'Shopify'
}

/**
 * 
 * @export
 * @interface IntegrationSubModuleLogDocument
 */
export interface IntegrationSubModuleLogDocument {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {LogLevel}
     * @memberof IntegrationSubModuleLogDocument
     */
    'level'?: LogLevel;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'message'?: string | null;
    /**
     * 
     * @type {IntegrationSubModuleType}
     * @memberof IntegrationSubModuleLogDocument
     */
    'type'?: IntegrationSubModuleType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleLogDocument
     */
    'integrationIdentifier'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IntegrationSubModuleType {
    Base = 'Base',
    OrderExport = 'OrderExport',
    InvoiceDocumentExport = 'InvoiceDocumentExport',
    CreditNoteDocumentExport = 'CreditNoteDocumentExport',
    OrderCancellationInvoiceExport = 'OrderCancellationInvoiceExport',
    CustomerExport = 'CustomerExport',
    OrderDeliveryInformationExport = 'OrderDeliveryInformationExport',
    ProductImport = 'ProductImport',
    InvoiceDocumentImport = 'InvoiceDocumentImport',
    ProductStockImport = 'ProductStockImport',
    OrderDeliveryInformationImport = 'OrderDeliveryInformationImport',
    WebhookRegistrar = 'WebhookRegistrar'
}

/**
 * 
 * @export
 * @interface InventoryItemUpdateRequest
 */
export interface InventoryItemUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof InventoryItemUpdateRequest
     */
    'inventory_item_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemUpdateRequest
     */
    'location_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemUpdateRequest
     */
    'available'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemUpdateRequest
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemUpdateRequest
     */
    'admin_graphql_api_id'?: string | null;
}
/**
 * 
 * @export
 * @interface InvokeShopifyAppDeploymentRequest
 */
export interface InvokeShopifyAppDeploymentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvokeShopifyAppDeploymentRequest
     */
    'shopDomain': string;
    /**
     * 
     * @type {string}
     * @memberof InvokeShopifyAppDeploymentRequest
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'variant_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'variant_title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'vendor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'fulfillment_service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'product_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'requires_shipping'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'taxable'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'gift_card'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'variant_inventory_management'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof LineItem
     */
    'properties'?: Array<any> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    'product_exists'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'fulfillable_quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'grams'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'total_discount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'fulfillment_status'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof LineItem
     */
    'duties'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'admin_graphql_api_id'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LogLevel {
    Trace = 'Trace',
    Debug = 'Debug',
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Critical = 'Critical',
    None = 'None'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum Operation {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete',
    Invoke = 'Invoke',
    Export = 'Export'
}

/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'variants'?: Array<Product> | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'priceGross'?: number | null;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof Product
     */
    'taxTypeIdentifier'?: TaxTypeIdentifier;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof Product
     */
    'productImages'?: Array<ProductImage> | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'vendor'?: string | null;
    /**
     * 
     * @type {Array<ProductStock>}
     * @memberof Product
     */
    'productStocks'?: Array<ProductStock> | null;
}
/**
 * 
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'uri'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductMapping
 */
export interface ProductMapping {
    /**
     * 
     * @type {ProductMappingItem}
     * @memberof ProductMapping
     */
    'externalProduct'?: ProductMappingItem;
    /**
     * 
     * @type {ProductMappingItem}
     * @memberof ProductMapping
     */
    'domainProduct'?: ProductMappingItem;
    /**
     * 
     * @type {ProductMappingStrategy}
     * @memberof ProductMapping
     */
    'strategy'?: ProductMappingStrategy;
}
/**
 * 
 * @export
 * @interface ProductMappingItem
 */
export interface ProductMappingItem {
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'imageUri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingItem
     */
    'productStockId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductMappingRequest
 */
export interface ProductMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'shopifyParentProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'shopifyVariantProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'shopifyVariantProductStockId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'domainProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMappingRequest
     */
    'domainProductStockId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductMappingStrategy {
    Proposal = 'Proposal',
    Mapped = 'Mapped',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface ProductStock
 */
export interface ProductStock {
    /**
     * 
     * @type {number}
     * @memberof ProductStock
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ShippingProviderEnum {
    Selbstabholung = 'Selbstabholung',
    Dhl = 'DHL',
    Standard = 'Standard',
    Hermes = 'Hermes',
    Ups = 'UPS',
    Dpd = 'DPD',
    Composite = 'COMPOSITE',
    Unknown = 'UNKNOWN',
    Fedex = 'FEDEX',
    Gls = 'GLS'
}

/**
 * 
 * @export
 * @interface ShopifyIntegrationConfigResponse
 */
export interface ShopifyIntegrationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'appClientId': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'appClientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'installationLink': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'shopifyAppVersionLink': string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'integrationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyIntegrationConfigResponse
     */
    'installed'?: boolean;
}
/**
 * 
 * @export
 * @interface ShopifyOrderExportConfigResponse
 */
export interface ShopifyOrderExportConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'integrationSubModuleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'exportFlag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'cronExpression'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'splitShippingLinesByTaxes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderExportConfigResponse
     */
    'shippingLineTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface ShopifyProductImportConfigResponse
 */
export interface ShopifyProductImportConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyProductImportConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductImportConfigResponse
     */
    'integrationSubModuleId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShopifyProductImportConfigResponse
     */
    'productIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShopifyProductImportConfigResponse
     */
    'productVariantIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductImportConfigResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface ShopifyProductSyncRequest
 */
export interface ShopifyProductSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof ShopifyProductSyncRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ShopifyProductVariantFieldIdentifier {
    Barcode = 'Barcode',
    Sku = 'Sku',
    Id = 'Id'
}

/**
 * 
 * @export
 * @interface ShopifyProductVariantFilterCriteria
 */
export interface ShopifyProductVariantFilterCriteria {
    /**
     * 
     * @type {ShopifyProductVariantFieldIdentifier}
     * @memberof ShopifyProductVariantFilterCriteria
     */
    'field'?: ShopifyProductVariantFieldIdentifier;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShopifyProductVariantFilterCriteria
     */
    'values'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ShopifyRedirectResponse
 */
export interface ShopifyRedirectResponse {
    /**
     * 
     * @type {string}
     * @memberof ShopifyRedirectResponse
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaxTypeIdentifier {
    Free = 'Free',
    Reduced = 'Reduced',
    Full = 'Full'
}

/**
 * 
 * @export
 * @interface UpsertHmacCredentialsRequest
 */
export interface UpsertHmacCredentialsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertHmacCredentialsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertHmacCredentialsRequest
     */
    'integrationId': string;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedIntegrationConfigRequest
 */
export interface UpsertInpettoClientBasedIntegrationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'integrationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedIntegrationConfigRequest
     */
    'installed'?: boolean;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
 */
export interface UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
     */
    'integrationSubModuleId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedOrderExportConfigRequest
 */
export interface UpsertInpettoClientBasedOrderExportConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderExportConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedOrderExportConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedOrderExportConfigRequest
     */
    'integrationSubModuleId'?: string;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedProductImportConfigRequest
 */
export interface UpsertInpettoClientBasedProductImportConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'integrationSubModuleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'productIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertInpettoClientBasedProductImportConfigRequest
     */
    'productVariantIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedProductMappingsRequest
 */
export interface UpsertInpettoClientBasedProductMappingsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductMappingsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {Array<InpettoClientBasedProductMappingRequest>}
     * @memberof UpsertInpettoClientBasedProductMappingsRequest
     */
    'mappings'?: Array<InpettoClientBasedProductMappingRequest> | null;
}
/**
 * 
 * @export
 * @interface UpsertInpettoClientBasedProductStockNotificationConfigRequest
 */
export interface UpsertInpettoClientBasedProductStockNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductStockNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertInpettoClientBasedProductStockNotificationConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertInpettoClientBasedProductStockNotificationConfigRequest
     */
    'integrationSubModuleId'?: string;
}
/**
 * 
 * @export
 * @interface UpsertOrderDeliveryInformationRequest
 */
export interface UpsertOrderDeliveryInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'trackingCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'transmissionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'shippingDateEarliest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'shippingDateLatest'?: string | null;
    /**
     * 
     * @type {Array<DeliveryPosition>}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'positions'?: Array<DeliveryPosition> | null;
    /**
     * 
     * @type {ShippingProviderEnum}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'shippingProvider'?: ShippingProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderDeliveryInformationRequest
     */
    'orderId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertProductStockRequest
 */
export interface UpsertProductStockRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductStockRequest
     */
    'productId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductStockRequest
     */
    'newQuantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertProductStockRequest
     */
    'isCloseout'?: boolean;
}
/**
 * 
 * @export
 * @interface UpsertShopifyIntegrationConfigRequest
 */
export interface UpsertShopifyIntegrationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'appClientId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'appClientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'installationLink': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'shopifyAppVersionLink': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'integrationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyIntegrationConfigRequest
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
 */
export interface UpsertShopifyOrderDeliveryInformationNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpsertShopifyOrderDeliveryInformationNotificationConfigRequest
     */
    'shippingProviderMapping'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyOrderExportConfigRequest
 */
export interface UpsertShopifyOrderExportConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'integrationSubModuleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'exportFlag'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'splitShippingLinesByTaxes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyOrderExportConfigRequest
     */
    'shippingLineTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyProductImportConfigRequest
 */
export interface UpsertShopifyProductImportConfigRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'integrationSubModuleId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'productIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'productVariantIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductImportConfigRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyProductMappingsRequest
 */
export interface UpsertShopifyProductMappingsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductMappingsRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {Array<ProductMappingRequest>}
     * @memberof UpsertShopifyProductMappingsRequest
     */
    'mappings'?: Array<ProductMappingRequest> | null;
}
/**
 * 
 * @export
 * @interface UpsertShopifyProductStockNotificationConfigRequest
 */
export interface UpsertShopifyProductStockNotificationConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductStockNotificationConfigRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertShopifyProductStockNotificationConfigRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertShopifyProductStockNotificationConfigRequest
     */
    'integrationSubModuleId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VersionKeyEnum {
    PhpVersion = 'phpVersion',
    ShopVersion = 'shopVersion',
    ErpVersion = 'erpVersion',
    InpettoConnectVersion = 'inpettoConnectVersion',
    DatabaseVersion = 'databaseVersion'
}

/**
 * 
 * @export
 * @interface VersionKeyEnumStringKeyValuePair
 */
export interface VersionKeyEnumStringKeyValuePair {
    /**
     * 
     * @type {VersionKeyEnum}
     * @memberof VersionKeyEnumStringKeyValuePair
     */
    'key'?: VersionKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof VersionKeyEnumStringKeyValuePair
     */
    'value'?: string | null;
}

/**
 * IntegrationLoggingApi - axios parameter creator
 * @export
 */
export const IntegrationLoggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IntegrationIdentifierType} [integrationIdentifier] 
         * @param {IntegrationSubModuleType} [subModuleType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogsGet: async (integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (integrationIdentifier !== undefined) {
                localVarQueryParameter['IntegrationIdentifier'] = integrationIdentifier;
            }

            if (subModuleType !== undefined) {
                localVarQueryParameter['SubModuleType'] = subModuleType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationLoggingApi - functional programming interface
 * @export
 */
export const IntegrationLoggingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationLoggingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IntegrationIdentifierType} [integrationIdentifier] 
         * @param {IntegrationSubModuleType} [subModuleType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLogsGet(integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationSubModuleLogDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLogsGet(integrationIdentifier, subModuleType, startDate, endDate, limit, manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationLoggingApi - factory interface
 * @export
 */
export const IntegrationLoggingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationLoggingApiFp(configuration)
    return {
        /**
         * 
         * @param {IntegrationIdentifierType} [integrationIdentifier] 
         * @param {IntegrationSubModuleType} [subModuleType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogsGet(integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options?: any): AxiosPromise<Array<IntegrationSubModuleLogDocument>> {
            return localVarFp.apiLogsGet(integrationIdentifier, subModuleType, startDate, endDate, limit, manufacturerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationLoggingApi - object-oriented interface
 * @export
 * @class IntegrationLoggingApi
 * @extends {BaseAPI}
 */
export class IntegrationLoggingApi extends BaseAPI {
    /**
     * 
     * @param {IntegrationIdentifierType} [integrationIdentifier] 
     * @param {IntegrationSubModuleType} [subModuleType] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {number} [limit] 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationLoggingApi
     */
    public apiLogsGet(integrationIdentifier?: IntegrationIdentifierType, subModuleType?: IntegrationSubModuleType, startDate?: string, endDate?: string, limit?: number, manufacturerId?: string, options?: AxiosRequestConfig) {
        return IntegrationLoggingApiFp(this.configuration).apiLogsGet(integrationIdentifier, subModuleType, startDate, endDate, limit, manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MagentoDeliveryInformationApi - axios parameter creator
 * @export
 */
export const MagentoDeliveryInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiDeliveryInformationConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/deliveryInformation/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest} [upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiDeliveryInformationConfigPost: async (upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest?: UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/deliveryInformation/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagentoDeliveryInformationApi - functional programming interface
 * @export
 */
export const MagentoDeliveryInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MagentoDeliveryInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiDeliveryInformationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiDeliveryInformationConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest} [upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiDeliveryInformationConfigPost(upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest?: UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiDeliveryInformationConfigPost(upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MagentoDeliveryInformationApi - factory interface
 * @export
 */
export const MagentoDeliveryInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MagentoDeliveryInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiDeliveryInformationConfigGet(manufacturerId?: string, options?: any): AxiosPromise<InpettoClientBasedOrderDeliveryInformationNotificationConfigResponse> {
            return localVarFp.magentoV1ApiDeliveryInformationConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest} [upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiDeliveryInformationConfigPost(upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest?: UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiDeliveryInformationConfigPost(upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagentoDeliveryInformationApi - object-oriented interface
 * @export
 * @class MagentoDeliveryInformationApi
 * @extends {BaseAPI}
 */
export class MagentoDeliveryInformationApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoDeliveryInformationApi
     */
    public magentoV1ApiDeliveryInformationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoDeliveryInformationApiFp(this.configuration).magentoV1ApiDeliveryInformationConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest} [upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoDeliveryInformationApi
     */
    public magentoV1ApiDeliveryInformationConfigPost(upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest?: UpsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, options?: AxiosRequestConfig) {
        return MagentoDeliveryInformationApiFp(this.configuration).magentoV1ApiDeliveryInformationConfigPost(upsertInpettoClientBasedOrderDeliveryInformationNotificationConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MagentoIntegrationApi - axios parameter creator
 * @export
 */
export const MagentoIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationConfigDelete: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/integration/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/integration/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedIntegrationConfigRequest} [upsertInpettoClientBasedIntegrationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationConfigPost: async (upsertInpettoClientBasedIntegrationConfigRequest?: UpsertInpettoClientBasedIntegrationConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/integration/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertInpettoClientBasedIntegrationConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertHmacCredentialsRequest} [upsertHmacCredentialsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationGenerateHmacCredentialsPost: async (upsertHmacCredentialsRequest?: UpsertHmacCredentialsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/integration/generate-hmac-credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertHmacCredentialsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationStatusGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/integration/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagentoIntegrationApi - functional programming interface
 * @export
 */
export const MagentoIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MagentoIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiIntegrationConfigDelete(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiIntegrationConfigDelete(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiIntegrationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InpettoClientBasedIntegrationConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiIntegrationConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedIntegrationConfigRequest} [upsertInpettoClientBasedIntegrationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiIntegrationConfigPost(upsertInpettoClientBasedIntegrationConfigRequest?: UpsertInpettoClientBasedIntegrationConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiIntegrationConfigPost(upsertInpettoClientBasedIntegrationConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertHmacCredentialsRequest} [upsertHmacCredentialsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiIntegrationGenerateHmacCredentialsPost(upsertHmacCredentialsRequest?: UpsertHmacCredentialsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InpettoClientHmacKeyPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiIntegrationGenerateHmacCredentialsPost(upsertHmacCredentialsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiIntegrationStatusGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InpettoClientBasedIntegrationStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiIntegrationStatusGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MagentoIntegrationApi - factory interface
 * @export
 */
export const MagentoIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MagentoIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationConfigDelete(manufacturerId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiIntegrationConfigDelete(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationConfigGet(manufacturerId?: string, options?: any): AxiosPromise<InpettoClientBasedIntegrationConfigResponse> {
            return localVarFp.magentoV1ApiIntegrationConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedIntegrationConfigRequest} [upsertInpettoClientBasedIntegrationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationConfigPost(upsertInpettoClientBasedIntegrationConfigRequest?: UpsertInpettoClientBasedIntegrationConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiIntegrationConfigPost(upsertInpettoClientBasedIntegrationConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertHmacCredentialsRequest} [upsertHmacCredentialsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationGenerateHmacCredentialsPost(upsertHmacCredentialsRequest?: UpsertHmacCredentialsRequest, options?: any): AxiosPromise<InpettoClientHmacKeyPair> {
            return localVarFp.magentoV1ApiIntegrationGenerateHmacCredentialsPost(upsertHmacCredentialsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiIntegrationStatusGet(manufacturerId?: string, options?: any): AxiosPromise<InpettoClientBasedIntegrationStatusResponse> {
            return localVarFp.magentoV1ApiIntegrationStatusGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagentoIntegrationApi - object-oriented interface
 * @export
 * @class MagentoIntegrationApi
 * @extends {BaseAPI}
 */
export class MagentoIntegrationApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoIntegrationApi
     */
    public magentoV1ApiIntegrationConfigDelete(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoIntegrationApiFp(this.configuration).magentoV1ApiIntegrationConfigDelete(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoIntegrationApi
     */
    public magentoV1ApiIntegrationConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoIntegrationApiFp(this.configuration).magentoV1ApiIntegrationConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertInpettoClientBasedIntegrationConfigRequest} [upsertInpettoClientBasedIntegrationConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoIntegrationApi
     */
    public magentoV1ApiIntegrationConfigPost(upsertInpettoClientBasedIntegrationConfigRequest?: UpsertInpettoClientBasedIntegrationConfigRequest, options?: AxiosRequestConfig) {
        return MagentoIntegrationApiFp(this.configuration).magentoV1ApiIntegrationConfigPost(upsertInpettoClientBasedIntegrationConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertHmacCredentialsRequest} [upsertHmacCredentialsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoIntegrationApi
     */
    public magentoV1ApiIntegrationGenerateHmacCredentialsPost(upsertHmacCredentialsRequest?: UpsertHmacCredentialsRequest, options?: AxiosRequestConfig) {
        return MagentoIntegrationApiFp(this.configuration).magentoV1ApiIntegrationGenerateHmacCredentialsPost(upsertHmacCredentialsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoIntegrationApi
     */
    public magentoV1ApiIntegrationStatusGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoIntegrationApiFp(this.configuration).magentoV1ApiIntegrationStatusGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MagentoOrderExportApi - axios parameter creator
 * @export
 */
export const MagentoOrderExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiOrdersConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/orders/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedOrderExportConfigRequest} [upsertInpettoClientBasedOrderExportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiOrdersConfigPost: async (upsertInpettoClientBasedOrderExportConfigRequest?: UpsertInpettoClientBasedOrderExportConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/orders/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertInpettoClientBasedOrderExportConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagentoOrderExportApi - functional programming interface
 * @export
 */
export const MagentoOrderExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MagentoOrderExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiOrdersConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InpettoClientBasedOrderExportConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiOrdersConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedOrderExportConfigRequest} [upsertInpettoClientBasedOrderExportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiOrdersConfigPost(upsertInpettoClientBasedOrderExportConfigRequest?: UpsertInpettoClientBasedOrderExportConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiOrdersConfigPost(upsertInpettoClientBasedOrderExportConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MagentoOrderExportApi - factory interface
 * @export
 */
export const MagentoOrderExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MagentoOrderExportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiOrdersConfigGet(manufacturerId?: string, options?: any): AxiosPromise<InpettoClientBasedOrderExportConfigResponse> {
            return localVarFp.magentoV1ApiOrdersConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedOrderExportConfigRequest} [upsertInpettoClientBasedOrderExportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiOrdersConfigPost(upsertInpettoClientBasedOrderExportConfigRequest?: UpsertInpettoClientBasedOrderExportConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiOrdersConfigPost(upsertInpettoClientBasedOrderExportConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagentoOrderExportApi - object-oriented interface
 * @export
 * @class MagentoOrderExportApi
 * @extends {BaseAPI}
 */
export class MagentoOrderExportApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoOrderExportApi
     */
    public magentoV1ApiOrdersConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoOrderExportApiFp(this.configuration).magentoV1ApiOrdersConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertInpettoClientBasedOrderExportConfigRequest} [upsertInpettoClientBasedOrderExportConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoOrderExportApi
     */
    public magentoV1ApiOrdersConfigPost(upsertInpettoClientBasedOrderExportConfigRequest?: UpsertInpettoClientBasedOrderExportConfigRequest, options?: AxiosRequestConfig) {
        return MagentoOrderExportApiFp(this.configuration).magentoV1ApiOrdersConfigPost(upsertInpettoClientBasedOrderExportConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MagentoProductImportApi - axios parameter creator
 * @export
 */
export const MagentoProductImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InpettoClientBasedProductSyncRequest} [inpettoClientBasedProductSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsActionSyncPost: async (inpettoClientBasedProductSyncRequest?: InpettoClientBasedProductSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/products/action/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inpettoClientBasedProductSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/products/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductImportConfigRequest} [upsertInpettoClientBasedProductImportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsConfigPost: async (upsertInpettoClientBasedProductImportConfigRequest?: UpsertInpettoClientBasedProductImportConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/products/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertInpettoClientBasedProductImportConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsMappingsGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/products/mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductMappingsRequest} [upsertInpettoClientBasedProductMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsMappingsPost: async (upsertInpettoClientBasedProductMappingsRequest?: UpsertInpettoClientBasedProductMappingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/products/mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertInpettoClientBasedProductMappingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetInpettoClientBasedProductsRequest} [getInpettoClientBasedProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsPost: async (getInpettoClientBasedProductsRequest?: GetInpettoClientBasedProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getInpettoClientBasedProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagentoProductImportApi - functional programming interface
 * @export
 */
export const MagentoProductImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MagentoProductImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InpettoClientBasedProductSyncRequest} [inpettoClientBasedProductSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductsActionSyncPost(inpettoClientBasedProductSyncRequest?: InpettoClientBasedProductSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductsActionSyncPost(inpettoClientBasedProductSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductsConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InpettoClientBasedProductImportConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductsConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductImportConfigRequest} [upsertInpettoClientBasedProductImportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductsConfigPost(upsertInpettoClientBasedProductImportConfigRequest?: UpsertInpettoClientBasedProductImportConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductsConfigPost(upsertInpettoClientBasedProductImportConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductsMappingsGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductsMappingsGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductMappingsRequest} [upsertInpettoClientBasedProductMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductsMappingsPost(upsertInpettoClientBasedProductMappingsRequest?: UpsertInpettoClientBasedProductMappingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductsMappingsPost(upsertInpettoClientBasedProductMappingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetInpettoClientBasedProductsRequest} [getInpettoClientBasedProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductsPost(getInpettoClientBasedProductsRequest?: GetInpettoClientBasedProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductsPost(getInpettoClientBasedProductsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MagentoProductImportApi - factory interface
 * @export
 */
export const MagentoProductImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MagentoProductImportApiFp(configuration)
    return {
        /**
         * 
         * @param {InpettoClientBasedProductSyncRequest} [inpettoClientBasedProductSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsActionSyncPost(inpettoClientBasedProductSyncRequest?: InpettoClientBasedProductSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiProductsActionSyncPost(inpettoClientBasedProductSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsConfigGet(manufacturerId?: string, options?: any): AxiosPromise<InpettoClientBasedProductImportConfigResponse> {
            return localVarFp.magentoV1ApiProductsConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductImportConfigRequest} [upsertInpettoClientBasedProductImportConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsConfigPost(upsertInpettoClientBasedProductImportConfigRequest?: UpsertInpettoClientBasedProductImportConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiProductsConfigPost(upsertInpettoClientBasedProductImportConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsMappingsGet(manufacturerId?: string, options?: any): AxiosPromise<Array<ProductMapping>> {
            return localVarFp.magentoV1ApiProductsMappingsGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductMappingsRequest} [upsertInpettoClientBasedProductMappingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsMappingsPost(upsertInpettoClientBasedProductMappingsRequest?: UpsertInpettoClientBasedProductMappingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiProductsMappingsPost(upsertInpettoClientBasedProductMappingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetInpettoClientBasedProductsRequest} [getInpettoClientBasedProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductsPost(getInpettoClientBasedProductsRequest?: GetInpettoClientBasedProductsRequest, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.magentoV1ApiProductsPost(getInpettoClientBasedProductsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagentoProductImportApi - object-oriented interface
 * @export
 * @class MagentoProductImportApi
 * @extends {BaseAPI}
 */
export class MagentoProductImportApi extends BaseAPI {
    /**
     * 
     * @param {InpettoClientBasedProductSyncRequest} [inpettoClientBasedProductSyncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductImportApi
     */
    public magentoV1ApiProductsActionSyncPost(inpettoClientBasedProductSyncRequest?: InpettoClientBasedProductSyncRequest, options?: AxiosRequestConfig) {
        return MagentoProductImportApiFp(this.configuration).magentoV1ApiProductsActionSyncPost(inpettoClientBasedProductSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductImportApi
     */
    public magentoV1ApiProductsConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoProductImportApiFp(this.configuration).magentoV1ApiProductsConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertInpettoClientBasedProductImportConfigRequest} [upsertInpettoClientBasedProductImportConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductImportApi
     */
    public magentoV1ApiProductsConfigPost(upsertInpettoClientBasedProductImportConfigRequest?: UpsertInpettoClientBasedProductImportConfigRequest, options?: AxiosRequestConfig) {
        return MagentoProductImportApiFp(this.configuration).magentoV1ApiProductsConfigPost(upsertInpettoClientBasedProductImportConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductImportApi
     */
    public magentoV1ApiProductsMappingsGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoProductImportApiFp(this.configuration).magentoV1ApiProductsMappingsGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertInpettoClientBasedProductMappingsRequest} [upsertInpettoClientBasedProductMappingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductImportApi
     */
    public magentoV1ApiProductsMappingsPost(upsertInpettoClientBasedProductMappingsRequest?: UpsertInpettoClientBasedProductMappingsRequest, options?: AxiosRequestConfig) {
        return MagentoProductImportApiFp(this.configuration).magentoV1ApiProductsMappingsPost(upsertInpettoClientBasedProductMappingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetInpettoClientBasedProductsRequest} [getInpettoClientBasedProductsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductImportApi
     */
    public magentoV1ApiProductsPost(getInpettoClientBasedProductsRequest?: GetInpettoClientBasedProductsRequest, options?: AxiosRequestConfig) {
        return MagentoProductImportApiFp(this.configuration).magentoV1ApiProductsPost(getInpettoClientBasedProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MagentoProductStocksApi - axios parameter creator
 * @export
 */
export const MagentoProductStocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductStocksConfigGet: async (manufacturerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/productStocks/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductStockNotificationConfigRequest} [upsertInpettoClientBasedProductStockNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductStocksConfigPost: async (upsertInpettoClientBasedProductStockNotificationConfigRequest?: UpsertInpettoClientBasedProductStockNotificationConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/api/productStocks/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertInpettoClientBasedProductStockNotificationConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagentoProductStocksApi - functional programming interface
 * @export
 */
export const MagentoProductStocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MagentoProductStocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductStocksConfigGet(manufacturerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InpettoClientBasedProductStockNotificationConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductStocksConfigGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductStockNotificationConfigRequest} [upsertInpettoClientBasedProductStockNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1ApiProductStocksConfigPost(upsertInpettoClientBasedProductStockNotificationConfigRequest?: UpsertInpettoClientBasedProductStockNotificationConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1ApiProductStocksConfigPost(upsertInpettoClientBasedProductStockNotificationConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MagentoProductStocksApi - factory interface
 * @export
 */
export const MagentoProductStocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MagentoProductStocksApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductStocksConfigGet(manufacturerId?: string, options?: any): AxiosPromise<InpettoClientBasedProductStockNotificationConfigResponse> {
            return localVarFp.magentoV1ApiProductStocksConfigGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertInpettoClientBasedProductStockNotificationConfigRequest} [upsertInpettoClientBasedProductStockNotificationConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1ApiProductStocksConfigPost(upsertInpettoClientBasedProductStockNotificationConfigRequest?: UpsertInpettoClientBasedProductStockNotificationConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.magentoV1ApiProductStocksConfigPost(upsertInpettoClientBasedProductStockNotificationConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagentoProductStocksApi - object-oriented interface
 * @export
 * @class MagentoProductStocksApi
 * @extends {BaseAPI}
 */
export class MagentoProductStocksApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductStocksApi
     */
    public magentoV1ApiProductStocksConfigGet(manufacturerId?: string, options?: AxiosRequestConfig) {
        return MagentoProductStocksApiFp(this.configuration).magentoV1ApiProductStocksConfigGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertInpettoClientBasedProductStockNotificationConfigRequest} [upsertInpettoClientBasedProductStockNotificationConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoProductStocksApi
     */
    public magentoV1ApiProductStocksConfigPost(upsertInpettoClientBasedProductStockNotificationConfigRequest?: UpsertInpettoClientBasedProductStockNotificationConfigRequest, options?: AxiosRequestConfig) {
        return MagentoProductStocksApiFp(this.configuration).magentoV1ApiProductStocksConfigPost(upsertInpettoClientBasedProductStockNotificationConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MagentoWebhooksApi - axios parameter creator
 * @export
 */
export const MagentoWebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {UpsertOrderDeliveryInformationRequest} [upsertOrderDeliveryInformationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1WebhooksOrderShippingDetailsPost: async (manufacturerId?: string, upsertOrderDeliveryInformationRequest?: UpsertOrderDeliveryInformationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/webhooks/orderShippingDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertOrderDeliveryInformationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {UpsertProductStockRequest} [upsertProductStockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1WebhooksProductStocksPost: async (manufacturerId?: string, upsertProductStockRequest?: UpsertProductStockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magento/v1/webhooks/productStocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductStockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagentoWebhooksApi - functional programming interface
 * @export
 */
export const MagentoWebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MagentoWebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {UpsertOrderDeliveryInformationRequest} [upsertOrderDeliveryInformationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1WebhooksOrderShippingDetailsPost(manufacturerId?: string, upsertOrderDeliveryInformationRequest?: UpsertOrderDeliveryInformationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityUpsertResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1WebhooksOrderShippingDetailsPost(manufacturerId, upsertOrderDeliveryInformationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {UpsertProductStockRequest} [upsertProductStockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magentoV1WebhooksProductStocksPost(manufacturerId?: string, upsertProductStockRequest?: UpsertProductStockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityUpsertResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magentoV1WebhooksProductStocksPost(manufacturerId, upsertProductStockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MagentoWebhooksApi - factory interface
 * @export
 */
export const MagentoWebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MagentoWebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {UpsertOrderDeliveryInformationRequest} [upsertOrderDeliveryInformationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1WebhooksOrderShippingDetailsPost(manufacturerId?: string, upsertOrderDeliveryInformationRequest?: UpsertOrderDeliveryInformationRequest, options?: any): AxiosPromise<EntityUpsertResult> {
            return localVarFp.magentoV1WebhooksOrderShippingDetailsPost(manufacturerId, upsertOrderDeliveryInformationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {UpsertProductStockRequest} [upsertProductStockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magentoV1WebhooksProductStocksPost(manufacturerId?: string, upsertProductStockRequest?: UpsertProductStockRequest, options?: any): AxiosPromise<EntityUpsertResult> {
            return localVarFp.magentoV1WebhooksProductStocksPost(manufacturerId, upsertProductStockRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagentoWebhooksApi - object-oriented interface
 * @export
 * @class MagentoWebhooksApi
 * @extends {BaseAPI}
 */
export class MagentoWebhooksApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {UpsertOrderDeliveryInformationRequest} [upsertOrderDeliveryInformationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoWebhooksApi
     */
    public magentoV1WebhooksOrderShippingDetailsPost(manufacturerId?: string, upsertOrderDeliveryInformationRequest?: UpsertOrderDeliveryInformationRequest, options?: AxiosRequestConfig) {
        return MagentoWebhooksApiFp(this.configuration).magentoV1WebhooksOrderShippingDetailsPost(manufacturerId, upsertOrderDeliveryInformationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {UpsertProductStockRequest} [upsertProductStockRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagentoWebhooksApi
     */
    public magentoV1WebhooksProductStocksPost(manufacturerId?: string, upsertProductStockRequest?: UpsertProductStockRequest, options?: AxiosRequestConfig) {
        return MagentoWebhooksApiFp(this.configuration).magentoV1WebhooksProductStocksPost(manufacturerId, upsertProductStockRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


